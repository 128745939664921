import Swal from 'sweetalert2'
const options = {
  allowOutsideClick: false,
  allowEscapeKey: false
}
const errorPrompt = (message, errorOptions = {}) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      type: 'error',
      text: message,
      ...options,
      ...errorOptions
    })
      .then((result) => {
        result.value ? resolve(result) : reject(result)
      })
  })
}
const infoPrompt = (message, infoOptions = {}) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      type: 'info',
      html: message,
      ...options,
      ...infoOptions
    })
      .then((result) => {
        result.value ? resolve(result) : reject(result)
      })
  })
}
const successPrompt = (message, successOptions = {}) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      type: 'success',
      html: message,
      ...options,
      ...successOptions
    })
      .then((result) => {
        result.value ? resolve(result) : reject(result)
      })
  })
}
const confirmPrompt = (confirmOptions, func) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      showCancelButton: true,
      ...options,
      ...confirmOptions
    })
      .then((result) => {
        if (result.value) {
          resolve(result)
        // } else if (result.dismiss === Swal.DismissReason.cancel) {
        //   if (confirmOptions !== undefined && confirmOptions !== null
        //       && confirmOptions.voucherFiles[0] !== undefined && confirmOptions.voucherFiles[0] !== null
        //       && confirmOptions.voucherFiles[0].base64File !== undefined && confirmOptions.voucherFiles[0].base64File !== null) {
        //     openBase64InNewTab(confirmOptions.voucherFiles[0].base64File)
        //     if (confirmOptions.voucherFiles[1] !== undefined && confirmOptions.voucherFiles[1] !== null
        //       && confirmOptions.voucherFiles[1].base64File !== undefined && confirmOptions.voucherFiles[1].base64File !== null) {
        //         openBase64InNewTab(confirmOptions.voucherFiles[1].base64File)
        //     }
        //     resolve(result)
        //   } else {
        //     if (!func) {
        //       return reject(result)
        //     }
        //     func()
        //     return false
        //   }
        } else {
          if (!func) {
            return reject(result)
          }
          func()
          return false
        }
      })
  })
}
function openBase64InNewTab (src) {
  // let pdfWindow = window.open('')
  fetch(src)
    .then(res => res.blob())
    .then(data => {
      const tab = window.open()
      const fileURL = window.URL.createObjectURL(data)
      tab.location.href = fileURL
    })
}
export {
  successPrompt,
  errorPrompt,
  infoPrompt,
  confirmPrompt
}
